<template>
  <div>
    <!-- Back button  if u want to use this breadcrumbs just remove the hidden class -->
    <div class="heading-sec">
      <div class="font-24 mt-10 ml-4">Edit Designer</div>
    </div>
    <!-- Top Bar Chart -->
    <v-card class="mt-2 mx-4">
      <div class="panel-content">
        <div class="row">
          <div class="col-md-12">
            <div class="widget">
              <div class="form-elements-sec">
                <div class="form-body">
                  <form brandName="details" class="formPart text-left" @submit.prevent="submitForm">
                    <!-- Name -->
                    <div>
                      <div
                        class="form-group col"
                        :class="{
                          'form-group--error': $v.details.brandName.$error,
                        }"
                      >
                        <label for="brandName">Brand Name:</label>
                        <input
                          type="name"
                          class="form-control"
                          id="brandName"
                          placeholder="Enter Name"
                          v-model.trim="details.brandName"
                          @input="setTouch('brandName')"
                        />
                        <div
                          class="error"
                          v-if="!$v.details.brandName.required && $v.details.brandName.$error"
                        >
                          Name is required
                        </div>
                      </div>
                    </div>

                    <!-- Plan -->
                    <div>
                      <div
                        class="form-group col"
                        :class="{
                          'form-group--error': $v.details.plan.$error,
                        }"
                      >
                        <label for="status">Plan:</label>
                        <v-select
                          :items="planTypes"
                          v-model="details.plan"
                          class="pt-0"
                          append-icon="▼"
                          dense
                          solo
                        ></v-select>
                        <div
                          class="error"
                          v-if="!$v.details.plan.required && $v.details.plan.$error"
                        >
                          Plan Type is required
                        </div>
                      </div>
                    </div>

                    <div>
                      <div
                        class="form-group col"
                        :class="{
                          'form-group--error': $v.details.vendorUrlCode.$error,
                        }"
                      >
                        <label for="name">Brand Url Code(Only Alphabets):</label>
                        <input
                          type="text"
                          class="form-control"
                          id="name"
                          placeholder="Enter Brand Url Code"
                          v-model.trim="details.vendorUrlCode"
                          @input="validateInput"
                          pattern="[A-Za-z]+"
                          title="Please enter only alphabets with no spaces in between."
                        />
                        <div
                          class="validation"
                          v-if="
                            !$v.details.vendorUrlCode.required && $v.details.vendorUrlCode.$error
                          "
                        >
                          Brand Url Code is required
                        </div>
                      </div>
                    </div>

                    <!--Status-->
                    <div>
                      <div
                        class="form-group col"
                        :class="{
                          'form-group--error': $v.details.userStatus.$error,
                        }"
                      >
                        <label for="status">Status:</label>
                        <v-select
                          :items="vendorStatus"
                          v-model="details.userStatus"
                          class="pt-0"
                          append-icon="▼"
                          dense
                          solo
                        ></v-select>
                        <div
                          class="error"
                          v-if="!$v.details.userStatus.required && $v.details.userStatus.$error"
                        >
                          Status is required
                        </div>
                      </div>
                    </div>

                    <!-- Email -->
                    <div>
                      <div
                        class="form-group col"
                        :class="{
                          'form-group--error': $v.details.email.$error,
                        }"
                      >
                        <label for="email">Email:</label>
                        <input
                          type="email"
                          class="form-control"
                          id="email"
                          placeholder="brandName@gmail.com"
                          v-model.trim="details.email"
                          @input="setTouch('email')"
                        />
                        <div
                          class="error"
                          v-if="!$v.details.email.required && $v.details.email.$error"
                        >
                          Email is required
                        </div>
                      </div>
                    </div>
                    <!-- phone no -->
                    <div>
                      <div
                        class="form-group col"
                        :class="{
                          'form-group--error': $v.details.mobile.$error,
                        }"
                      >
                        <label for="mobile">Mobile No:</label>
                        <input
                          type="number"
                          class="form-control"
                          id="mobile"
                          placeholder="Enter Mobile No"
                          v-model.trim="details.mobile"
                          @input="setTouch('mobile')"
                        />
                        <div
                          class="error"
                          v-if="!$v.details.mobile.required && $v.details.mobile.$error"
                        >
                          Mobile No is required
                        </div>
                      </div>
                    </div>
                    <!-- Brand Code-->
                    <div class="d-flex">
                      <div
                        class="form-group col"
                        :class="{
                          'form-group--error': $v.details.identificationCode.$error,
                        }"
                      >
                        <label for="identificationCode" class="mb-2"
                          >Code (Min 3 characters required) :
                          <span class="ma-2">
                            <v-btn @click="editConfirm">
                              <img src="@/assets/Images/Edit.svg" alt="logo" class="pr-1" />
                              <span class="poppins-medium font-12"> Edit</span>
                            </v-btn>
                          </span>
                        </label>
                        <input
                          type="text"
                          class="form-control"
                          id="code"
                          placeholder="Enter Brand Code"
                          v-model="details.identificationCode"
                          @input="setTouch('identificationCode')"
                          minlength="3"
                          maxlength="3"
                          oninput="this.value=this.value.toUpperCase()"
                          :readonly="edit"
                        />
                        <div
                          class="validation"
                          v-if="
                            !$v.details.identificationCode.required &&
                            $v.details.identificationCode.$error
                          "
                        >
                          Code is required
                        </div>
                      </div>
                      <!-- <div class="">
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              min-width="40px"
                              height="40px"
                              color="green"
                              @click="editConfirm"
                              v-bind="attrs"
                              v-on="on"
                              append
                            >
                              <b-icon-pencil class="text-white"></b-icon-pencil>
                            </v-btn>
                          </template>
                          <span>Edit</span>
                        </v-tooltip>
                      </div> -->
                    </div>
                    <!--Mark down %-->
                    <div>
                      <div class="form-group col">
                        <label for="marginPercentage">Mark down %:</label>
                        <input
                          type="number"
                          class="form-control"
                          id="mobile"
                          placeholder="Enter Mark down Percentage"
                          v-model.trim="details.marginPercentage"
                          min="0"
                          max="99"
                        />
                      </div>
                    </div>
                    <div>
                      <div class="form-group col">
                        <label for="secondaryEmail">Secondary Email:</label>
                        <input
                          type="email"
                          class="form-control"
                          id="mobile"
                          placeholder="Enter Email"
                          v-model.trim="details.secondaryContact.email"
                        />
                        <!-- <div
                          class="error"
                          v-if="!$v.details.mobile.required && $v.details.mobile.$error"
                        >
                          Secondary Email  is required
                        </div> -->
                      </div>
                    </div>
                    <div>
                      <div
                        class="form-group col"
                        :class="{
                          'form-group--error': $v.details.mobile.$error,
                        }"
                      >
                        <label for="secondaryMobile">Secondary Mobile:</label>
                        <input
                          type="number"
                          class="form-control"
                          id="mobile"
                          placeholder="Enter Mobile No"
                          v-model.trim="details.secondaryContact.mobile"
                        />
                        <!-- <div
                          class="error"
                          v-if="!$v.details.mobile.required && $v.details.mobile.$error"
                        >
                           Secondary Mobile No is required
                        </div> -->
                      </div>
                    </div>

                    <!--Brand Page URL-->
                    <div>
                      <div class="form-group col">
                        <label for="brandName">Brand Page Url:</label>
                        <input
                          type="name"
                          class="form-control"
                          id="brandName"
                          placeholder="Enter Brand Page Url"
                          v-model.trim="details.brandPageUrl"
                          @input="setTouch('brandPageUrl')"
                        />
                      </div>
                    </div>

                    <!--Image Upload-->
                    <div class="d-flex">
                      <div class="form-group col">
                        <div>
                          <label for="image">Brand Banner Image(Mobile):</label>
                          <div class="font-12 black--text font-weight-bold">
                            (376 W &#10005; 240 H)
                          </div>
                          <v-file-input
                            prepend-icon=""
                            type="file"
                            class="form-control"
                            id="banner-image"
                            v-model.trim="details.mobileBrandImage"
                            placeholder=" Select Image"
                            accept="image/jpeg, image/png"
                            @change="handleMobileImage"
                          ></v-file-input>
                        </div>
                        <div class="preview-image border p-2 mt-3 ml-3" v-if="mobileUrl">
                          <v-img :src="mobileUrl" height="230" width="300px" contain></v-img>
                        </div>
                      </div>

                      <div class="form-group col">
                        <div>
                          <label for="image"> Brand Banner Image(Desktop):</label>
                          <div class="font-12 black--text font-weight-bold">
                            (1442 W &#10005; 400 H)
                          </div>
                          <v-file-input
                            prepend-icon=""
                            type="file"
                            class="form-control"
                            id="banner-image"
                            v-model.trim="details.desktopBrandImage"
                            placeholder=" Select Image"
                            @change="handleDesktopImage"
                            accept="image/jpeg, image/png"
                          ></v-file-input>
                        </div>
                        <div class="preview-image border p-2 mt-3 mr-3" v-if="desktopUrl">
                          <v-img :src="desktopUrl" height="230" width="300px" contain></v-img>
                        </div>
                      </div>
                    </div>
                    <!-- button -->
                    <div class="mt-2 mb-2" v-if="loader" align="center">
                      <v-progress-circular indeterminate color="primary"></v-progress-circular>
                    </div>
                    <div class="btnPart text-center my-4">
                      <button type="submit" class="btn btn-success" :disabled="loader">Save</button>
                      <p class="typo__p" v-if="submitStatus === 'OK'">
                        Thanks for your submission!
                      </p>
                      <p class="typo__p" v-if="submitStatus === 'ERROR'">
                        Please fill the form correctly.
                      </p>

                      <button type="reset" class="btn btn-danger mx-3" v-on:click="resetForm()">
                        Cancel
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </v-card>
    <ConfirmDlg ref="confirm" />
  </div>
</template>

<script>
import { required, email, minValue, maxValue } from 'vuelidate/lib/validators';
import customerService from '../../services/customerService';
import imageUploadService from '../../services/imageUploadService';
import ConfirmDlg from '../ConfirmDlg.vue';
import productService from '../../services/productService';
import Edit from '@/assets/Images/Edit.svg';
import vendorService from '../../services/vendorService';

export default {
  components: { ConfirmDlg },
  data() {
    return {
      ex7: 'red',
      details: {
        brandName: '',
        email: '',
        mobile: '',
        userStatus: '',
        marginPercentage: '',
        secondaryContact: {
          email: '',
          mobile: '',
        },
        identificationCode: '',
        mobileBrandImage: '',
        desktopBrandImage: '',
        brandPageUrl: '',
        vendorUrlCode: '',
        plan: '',
      },
      brand: '',
      code: '',
      row: null,
      submitStatus: '',
      vendorStatus: ['pre-onboarding', 'onboard', 'active', 'inactive'],
      mobileUrl: '',
      desktopUrl: '',
      edit: true,
      loader: false,
      planTypes: ['trial', 'basic', 'pro', 'advance'],
    };
  },

  validations: {
    details: {
      brandName: {
        required,
      },
      email: {
        required,
        email,
      },
      mobile: {
        required,
      },
      // marginPercentage: {
      //   required,
      //   minValue: minValue(0),
      //   maxValue: maxValue(99),
      // },
      userStatus: {
        required,
      },
      identificationCode: {
        required,
      },
      // mobileBrandImage: {
      //   required,
      // },
      // desktopBrandImage: {
      //   required,
      // },
      vendorUrlCode: {
        required,
      },
      plan: {
        required,
      },
    },
  },
  async created() {
    this.id = JSON.parse(localStorage.getItem('selectedVendor'));
    if (this.id) {
      this.getOneUser(this.id);
    }
  },
  methods: {
    async getOneUser(id) {
      console.log(id, 'id');
      const result = await customerService.getOneIframeCustomer(id);
      this.details.brandName = result.data.brandName;
      this.brand = result.data.brandName;
      this.code = result.data.identificationCode;
      this.details.email = result.data.email;
      this.details.mobile = result.data.mobile;
      this.details.userStatus = result.data.userStatus;
      this.details.marginPercentage = result.data.marginPercentage;
      this.details.identificationCode = result.data.identificationCode;
      this.details.secondaryContact.email = result.data.secondaryContact.email;
      this.details.secondaryContact.mobile = result.data.secondaryContact.mobile;
      this.details.brandPageUrl = result.data.brandPageUrl;
      this.mobileUrl = result.data.mobileBrandImage;
      this.desktopUrl = result.data.desktopBrandImage;
      this.details.mobileBrandImage = result.data.mobileBrandImage;
      this.details.desktopBrandImage = result.data.desktopBrandImage;
      this.details.vendorUrlCode = result.data.vendorUrlCode;
      this.details.plan = result.data.plan;

      console.log(result, 'result');
    },
    setTouch(fields) {
      if (fields === 'brandName') {
        this.$v.details.brandName.$touch();
      } else if (fields === 'email') {
        this.$v.details.email.$touch();
      } else if (fields === 'mobile') {
        this.$v.details.mobile.$touch();
      }
      //  else if (fields === 'marginPercentage') {
      //   this.$v.details.marginPercentage.$touch();
      // }
      else if (fields === 'userStatus') {
        this.$v.details.userStatus.$touch();
      } else if (fields === 'identificationCode') {
        this.$v.details.identificationCode.$touch();
      } else if (fields === 'plan') {
        this.$v.details.plan.$touch();
      }
    },
    // this is use for save button
    async submitForm() {
      this.loader = true;
      const id = JSON.parse(localStorage.getItem('selectedVendor'));
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.$store.commit('setSnackbar', {
          content: 'please fill form correctly',
          icon: 'mdi-alert-circle',
          color: 'error ',
          isVisible: true,
        });

        this.submitStatus = 'ERROR';
        this.loader = false;
      } else {
        console.log(id, 'id');
        this.submitStatus = 'OK';
        const data = this.details;
        delete data.email;
        const result = await customerService.updateIFrameUserData(id, data);
        if (this.brand != this.details.brandName) {
          await this.updateElasticBrandData();
        }
        if (this.code != this.details.identificationCode) {
          await this.updateVendorCode();
        }
        if (result.status === 200) {
          this.loader = false;
          this.$store.commit('setSnackbar', {
            content: 'Submitted Successfully',
            icon: 'mdi-check-circle',
            color: 'success ',
            isVisible: true,
          });
          this.$router.go();
        }
        console.log(result);
      }
    },
    resetForm() {
      this.details = 'blank';
      this.$nextTick(() => {
        this.$v.$reset();
        this.$router.push('/viewVendors');
      });
    },
    validateInput() {
      this.details.vendorUrlCode = this.details.vendorUrlCode.replace(/[^A-Za-z]/g, '');
    },
    async handleDesktopImage(file) {
      this.desktopUrl = URL.createObjectURL(this.details.desktopBrandImage);
      imageUploadService.uploadImage(file, (result) => {
        if (result.status === 200) {
          this.$store.commit('setSnackbar', {
            content: 'Image Uploaded Successfully',
            icon: 'mdi-check-circle',
            color: 'success ',
            isVisible: true,
          });
          console.log('image', result.data.meta.location);
          this.details.desktopBrandImage = result.data.meta.location;
          this.preview = result;
        } else {
          this.$store.commit('setSnackbar', {
            content: 'Something went wrong !',
            icon: 'mdi-alert-circle',
            color: 'error ',
            isVisible: true,
          });
        }
      });
    },
    async handleMobileImage(file) {
      this.mobileUrl = URL.createObjectURL(this.details.mobileBrandImage);

      imageUploadService.uploadImage(file, (result) => {
        if (result.status === 200) {
          console.log(result.data.meta.location, 'meta');
          this.details.mobileBrandImage = result.data.meta.location;

          this.$store.commit('setSnackbar', {
            content: 'Image Uploaded Successfully',
            icon: 'mdi-check-circle',
            color: 'success ',
            isVisible: true,
          });
        } else {
          this.$store.commit('setSnackbar', {
            content: 'Something went wrong !',
            icon: 'mdi-alert-circle',
            color: 'error ',
            isVisible: true,
          });
        }
      });
    },
    async updateVendorCode() {
      const data = {};
      data.vendor = this.id;
      const result = await productService.skuUpdateForAll(data);
      if (result.status == 200) {
        this.$store.commit('setSnackbar', {
          content: 'Vendor Code has been saved successfully!',
          icon: 'mdi-check-circle',
          color: 'success ',
          isVisible: true,
        });
      } else {
        this.$store.commit('setSnackbar', {
          content: 'Something went wrong while updating Vendor Code !',
          icon: 'mdi-alert-circle',
          color: 'error ',
          isVisible: true,
        });
      }
      console.log(result);
    },
    async updateElasticBrandData() {
      const data = {};
      data.vendorId = this.id;
      const result = await vendorService.syncBrandElasticData(data);
      if (result.status == 200) {
        this.$store.commit('setSnackbar', {
          content: 'Vendor Data has been saved successfully!',
          icon: 'mdi-check-circle',
          color: 'success ',
          isVisible: true,
        });
      } else {
        this.$store.commit('setSnackbar', {
          content: 'Something went wrong while updating Vendor Data !',
          icon: 'mdi-alert-circle',
          color: 'error ',
          isVisible: true,
        });
      }
      console.log(result);
    },
    async editConfirm() {
      if (
        await this.$refs.confirm.open('Confirm', `Are you sure you want to Change Vendor Code?`)
      ) {
        this.edit = false;
        //  await this.updateVendorCode();
        console.log('got confirmation');
      } else {
        this.edit = true;
      }
    },
  },
};
</script>
