<template>
  <div>
    <div class="mx-4 mt-16 justify-center" align="center">
      <v-tabs v-model="tab" class="tab-section" fixed-tabs height="35px">
        <v-tabs-slider color="#72593F" class="tabs-slider-none"></v-tabs-slider>
        <v-tab v-for="item in items" :key="item.tab" :href="'#' + item.name">
          {{ item.name }}
        </v-tab>
      </v-tabs>
    </div>
    <v-tabs-items v-model="tab" class="mt-3">
      <v-tab-item v-for="item in items" :key="item.tab" :value="item.name">
        <div v-if="item.tab === 'VENDORS'">
          <VendorCard />
        </div>
        <!-- <div v-if="item.tab === 'PRODUCTS'">
          <Products />
        </div>
        <div v-if="item.tab === 'ORDERS'">
          <Orders />
        </div> -->
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import VendorCard from '@/components/Vendor/VendorCard.vue';
//import Products from '@/components/Vendor/Products.vue';
//import Orders from '@/components/Vendor/Orders.vue';

export default {
  components: {
    VendorCard,
    // Products,
    // Orders,
  },
  data() {
    return {
      tab: null,
      products: [],
      orders: [],
      items: [
        { tab: 'VENDORS', name: 'Vendor' },
        // { tab: 'PRODUCTS', name: 'Products' },
        // { tab: 'ORDERS', name: 'Orders' },
      ],
    };
  },
  methods: {},
};
</script>
<style lang="scss" scoped>
.tab-section {
  border: 1px solid #dcdcdc;
  box-sizing: border-box;
  border-radius: 5px;
  width: 100%;
  .v-tab--active {
    background: black;
    color: white !important;
    border-radius: 6px 6px 6px 6px;
  }
}
.tabs-slider-none {
  display: none;
}
@media only screen and (min-width: 960px) {
  .tab-section {
    width: 50%;
  }
}
</style>
